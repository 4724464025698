import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { getAppUrl, useSites } from '@braincube/header';

import { useApps } from 'ContextualizedApp/contexts/apps';
import { useMenuContext } from 'Menu/contexts';
import getSvgIcons from 'services/svg';

function AppListItem({ pkgName, embedded }) {
    const { setIsOpen } = useMenuContext();
    const history = useHistory();
    const { profileApps } = useApps();
    const { braincubeProduct, selectedElement, selectedSite } = useSites();

    const app = useMemo(() => profileApps.find((profileApp) => profileApp.pkgName === pkgName), [pkgName, profileApps]);

    const open = useCallback(() => {
        setIsOpen(false);

        if (embedded) {
            history.push(app.pkgName);
        } else {
            let url = getAppUrl(
                app?.appUrl,
                braincubeProduct?.name,
                braincubeProduct?.productId,
                selectedElement?.uuid,
                selectedSite?.siteUuid
            );

            if (url.includes('administration')) {
                url += `&productUuid=${braincubeProduct.productId}`;
            }

            window.open(url, '_blank');
        }
    }, [setIsOpen, embedded, history, app, braincubeProduct, selectedElement, selectedSite]);

    return app ? (
        <ListItem onClick={open} button>
            <ListItemIcon>{getSvgIcons(app.appIcon)}</ListItemIcon>
            <ListItemText primary={app.appName} />
        </ListItem>
    ) : null;
}

AppListItem.propTypes = {
    pkgName: PropTypes.string.isRequired,
    embedded: PropTypes.bool,
};

AppListItem.defaultProps = {
    embedded: false,
};

export default AppListItem;
