import { fetchWithBearer } from '@braincube/jwt-browser';

function getDisplayType(apiName) {
    return fetchWithBearer(`https://${apiName}.${window.BC_API_ENDPOINTS_CONF.base}/braincube-web/rest/session/displayType`).then(
        (response) => {
            if (response.ok) {
                try {
                    return response.json();
                } catch {
                    return Promise.reject(response);
                }
            }

            return Promise.reject(response);
        }
    );
}

function postDisplayType(displayType, apiName) {
    return fetchWithBearer(`https://${apiName}.${window.BC_API_ENDPOINTS_CONF.base}/braincube-web/rest/session/displayType`, {
        method: 'POST',
        body: JSON.stringify({ id: displayType }),
    });
}

export { getDisplayType, postDisplayType };
