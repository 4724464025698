import { useHistory } from 'react-router-dom';

const useIsJspPage = () => {
    const history = useHistory();

    try {
        return history.location.state.isJspPage;
    } catch (e) {
        return false;
    }
};

export default useIsJspPage;
