import React from 'react';
import * as MuiIcons from '@mui/icons-material';

import * as SvgIcons from '@braincube/svg';

const allIcons = { ...MuiIcons, ...SvgIcons };

function getSvgIcons(name, extraProps) {
    if (Object.prototype.hasOwnProperty.call(allIcons, name)) {
        return React.createElement(allIcons[name], extraProps);
    }

    return <SvgIcons.Node {...extraProps} />;
}

export default getSvgIcons;
