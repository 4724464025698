import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { buildTheme } from '@braincube/theme';
import { HeaderContextProvider } from '@braincube/header';
import Logger from '@braincube/logger';

import App from './App';

const theme = buildTheme();

const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');

script.async = false;
script.type = 'text/javascript';

if (process.env.NODE_ENV === 'development') {
    script.src = `https://cdn.test.mybraincube.com/configuration.js?timestamp=${Date.now()}`;
} else {
    script.src = `https://${window.location.hostname.replace(/^[^.]+/, 'cdn')}/configuration.js?timestamp=${Date.now()}`;
}

const queryClient = new QueryClient();

function Init() {
    useEffect(() => {
        Logger.configure({
            sentry: process.env.REACT_APP_LOGGER_SENTRY === 'true',
            appName: 'mario',
            version: process.env.REACT_APP_VERSION,
            sentryProjectId: 24,
            sentryToken: '2b7cb09003c64ff384888b6e0fbfe048',
        });
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3} preventDuplicate>
                <QueryClientProvider client={queryClient}>
                    <HeaderContextProvider withDatasources>
                        <App />
                    </HeaderContextProvider>
                </QueryClientProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

script.onload = function render() {
    createRoot(document.getElementById('root')).render(<Init />);
};

head.appendChild(script);
