import { fetchWithBearer } from '@braincube/jwt-browser';

function postPresentationMode(apiName) {
    return fetchWithBearer(
        `https://${apiName}.${window.BC_API_ENDPOINTS_CONF.base}/braincube-web/rest/session/presentationMode`,
        {
            method: 'POST',
        }
    ).then((response) => {
        if (response.ok) {
            return Promise.resolve(response);
        }

        return Promise.reject(response);
    });
}

export default postPresentationMode;
