import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { useSites } from '@braincube/header';
import { useI18n } from '@braincube/i18n';
import { BraincubeHttpError, checkJwt, isPresentationMode, refreshJwt } from '@braincube/jwt-browser';

import { redirectToLogin } from 'services/api';
import postPresentationMode from 'Menu/components/PresentationMode/services';

const PresentationModeContext = createContext({
    presentationMode: false,
    setPresentationMode: () => {},
});

export function PresentationModeContextProvider({ children }) {
    const [presentationMode, setPresentationMode] = useState(false);
    const { braincubeProduct } = useSites();
    const { enqueueSnackbar } = useSnackbar();
    const i18n = useI18n();

    useEffect(() => {
        checkJwt().then(() => {
            setPresentationMode(isPresentationMode());
        });
    }, []);

    const handlePresentationModeChange = useCallback(
        (newPresentationMode) => {
            if (braincubeProduct) {
                if (newPresentationMode) {
                    postPresentationMode(braincubeProduct.name)
                        .then(refreshJwt)
                        .then(() => setPresentationMode(true))
                        .catch((response) => {
                            if (
                                (response instanceof BraincubeHttpError && response?.httpStatusCode === 403) ||
                                response?.status === 403
                            ) {
                                redirectToLogin();
                            } else {
                                enqueueSnackbar(i18n.tc('notifications.jwtError'), { variant: 'error' });
                            }
                        });
                } else if (isPresentationMode()) {
                    postPresentationMode(braincubeProduct.name).then(redirectToLogin).catch(redirectToLogin);
                }
            }
        },
        [braincubeProduct, enqueueSnackbar, i18n]
    );

    const value = useMemo(
        () => ({ presentationMode, setPresentationMode: handlePresentationModeChange }),
        [handlePresentationModeChange, presentationMode]
    );

    return <PresentationModeContext.Provider value={value}>{children}</PresentationModeContext.Provider>;
}

export const usePresentationModeContext = () => useContext(PresentationModeContext);
