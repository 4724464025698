import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { List, Divider, styled } from '@mui/material';

import ResponsiveDisplay from './ResponsiveDisplay';

const StyledHorizontalTabs = styled('div')({
    display: 'flex',
});

function HorizontalTabs({ children }) {
    return <StyledHorizontalTabs>{children}</StyledHorizontalTabs>;
}

function VerticalTabs({ children }) {
    return (
        <>
            <List disablePadding>{children}</List>
            <Divider />
        </>
    );
}

/**
 * Tabs make it easy to explore and switch between different views.
 */
function Tabs({ value, onChange, children }) {
    const childs = React.Children.map(children, (child, index) => {
        if (index === value) {
            return React.cloneElement(child, { active: true, onClick: () => onChange(index) });
        }
        return React.cloneElement(child, { onClick: () => onChange(index) });
    });

    const horizontal = useMemo(() => <HorizontalTabs>{childs}</HorizontalTabs>, [childs]);
    const vertical = useMemo(() => <VerticalTabs>{childs}</VerticalTabs>, [childs]);

    return <ResponsiveDisplay horizontal={horizontal} vertical={vertical} />;
}

Tabs.propTypes = {
    /**
     * The value of the currently selected Tab.
     */
    value: PropTypes.number.isRequired,
    /**
     * Callback fired when the value changes. `function(value: number) => void`
     */
    onChange: PropTypes.func.isRequired,
};

export default Tabs;
