import { styled } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Header,
    HeaderAction,
    HeaderActions,
    HeaderLogo,
    HeaderPalette,
    HeaderUniverseMenu,
    useSites,
    HeaderWidgets,
} from '@braincube/header';
import { checkJwt, storeProductUuid } from '@braincube/jwt-browser';
import * as SvgIcons from '@braincube/svg';

import { useApps } from 'ContextualizedApp/contexts/apps';
import { getBcVersion, needsRefresh, postDatasource } from 'ContextualizedApp/services';
import { useMenuContext } from 'Menu/contexts';
import { IframeContext } from 'Iframe/contexts/iframeContext';
import MyAvatar from 'MyAvatar';
import HeaderTab from 'HeaderTabs/HeaderTab';
import HeaderTabs from 'HeaderTabs/HeaderTabs';

const StyledNoBraincubeSpacer = styled('div')({
    display: 'flex',
    flex: 1,
});

function MarioHeader() {
    const { braincubeProduct, selectedElement, siteChangeInProgress } = useSites();
    const { setDatasourceChangeInProgress } = useContext(IframeContext);
    const { setIsOpen } = useMenuContext();
    const history = useHistory();
    const [bcVersion, setBcVersion] = useState(null);
    const { tabApps, currentApp } = useApps();

    useEffect(() => {
        if (braincubeProduct && braincubeProduct.name !== '') {
            if (needsRefresh(braincubeProduct.url)) {
                storeProductUuid(braincubeProduct.productId);
                checkJwt().finally(() => {
                    window.location.replace(braincubeProduct.url);
                });
            } else {
                getBcVersion(braincubeProduct.name).then(setBcVersion);
            }
        }
    }, [braincubeProduct, history.location.pathname]);

    useEffect(() => {
        if (braincubeProduct && selectedElement?.bcId) {
            setDatasourceChangeInProgress(true);
            postDatasource(selectedElement.bcId, braincubeProduct.name)
                .then(() => setDatasourceChangeInProgress(false))
                .catch(() => setDatasourceChangeInProgress(false));
        }
    }, [selectedElement?.bcId, braincubeProduct, setDatasourceChangeInProgress]);

    const currentAppTabValue = useMemo(() => {
        return currentApp ? currentApp.tabId : -1;
    }, [currentApp]);

    const appData = useMemo(() => tabApps.find((app) => app.pkgName === 'braincube-data'), [tabApps]);

    const onTabChange = useCallback(
        (tab) => {
            const found = tabApps.find((app) => app.tabId === tab);
            if (currentAppTabValue === tab) {
                history.replace(found.pkgName);
                window.location.reload();
            } else {
                history.push(found.pkgName, { isJspPage: found.appUrl.includes('.jsp') });
            }
        },
        [currentAppTabValue, history, tabApps]
    );

    const onAddStudyClick = useCallback(
        () => (appData ? history.push({ pathname: `/${appData.pkgName}`, search: '?isQSPopup=true' }) : undefined),
        [appData, history]
    );

    const tabs = useMemo(
        () =>
            tabApps.map(({ appUuid, appIcon, appName }) => (
                <HeaderTab key={appUuid} icon={SvgIcons[appIcon] || Node} label={appName} />
            )),
        [tabApps]
    );

    const openMenu = useCallback(() => setIsOpen(true), [setIsOpen]);

    return (
        <Header>
            <HeaderPalette />
            <HeaderLogo title={bcVersion} />
            <HeaderUniverseMenu onAddStudyClick={onAddStudyClick} />
            {braincubeProduct && (
                <HeaderTabs onChange={onTabChange} value={currentAppTabValue}>
                    {tabs}
                </HeaderTabs>
            )}
            {!siteChangeInProgress && (
                <>
                    {!braincubeProduct && <StyledNoBraincubeSpacer />}
                    <HeaderActions>
                        <HeaderWidgets />

                        <HeaderAction onClick={openMenu} icon={MyAvatar} label="Menu" horizontalOnly />
                    </HeaderActions>
                </>
            )}
        </Header>
    );
}

export default MarioHeader;
