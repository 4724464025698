import React, { useCallback } from 'react';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, styled, Switch } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import { Presentation } from '@braincube/svg';

import { usePresentationModeContext } from 'Menu/components/PresentationMode/contexts';

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    paddingRight: theme.spacing(3),
}));

function PresentationMode() {
    const i18n = useI18n();

    const { presentationMode, setPresentationMode } = usePresentationModeContext();

    const handleChange = useCallback(
        (event) => {
            setPresentationMode(event.target.checked);
        },
        [setPresentationMode]
    );

    return (
        <ListItem>
            <ListItemIcon>
                <Presentation />
            </ListItemIcon>
            <StyledListItemText primary={i18n.tc('menu.presentationMode')} />
            <ListItemSecondaryAction>
                <Switch color="primary" edge="end" onChange={handleChange} checked={presentationMode} />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default PresentationMode;
