import React, { createContext, useMemo, useState } from 'react';

import { usePreferences } from '@braincube/header';
import { getLocaleKey } from '@braincube/i18n';

const MarioContext = createContext({
    locale: null,
    setLocale: () => {},
    isBusy: false,
    setIsBusy: () => {},
});

function MarioContextProvider({ children }) {
    const preferences = usePreferences();
    const [locale, setLocale] = useState(getLocaleKey(preferences.user.general.locale));
    const [isBusy, setIsBusy] = useState(false);

    const value = useMemo(() => ({ locale, setLocale, isBusy, setIsBusy }), [isBusy, locale]);

    return <MarioContext.Provider value={value}>{children}</MarioContext.Provider>;
}

export { MarioContext, MarioContextProvider };
