import { Button, Stack, styled } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { useHead, useSites } from '@braincube/header';
import { ErrorPage, SupportContact } from '@braincube/ui';
import { useI18n } from '@braincube/i18n';

import { MarioContext } from 'App/contexts';
import { IframeContext } from 'Iframe/contexts/iframeContext';
import { redirectToLogin } from 'services/api';
import useIsJspPage from 'hooks/useIsJspPage';
import healthCheck from 'Iframe/services/api';

const StyledIframe = styled('iframe')(({ theme }) => ({
    border: 'none',
    width: '100%',
    height: `calc(100% - ${theme.header.height}px - ${theme.spacing(1)})`,
}));

function reload() {
    window.location.reload();
}

function Iframe({ app, wid }) {
    const i18n = useI18n();
    const { ref, closeWorkspace, setCloseWorkspace, datasourceChangeInProgress } = useContext(IframeContext);
    const { braincubeProduct, selectedElement, siteChangeInProgress } = useSites();
    const { setIsBusy } = useContext(MarioContext);
    const [error, setError] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const isJspPage = useIsJspPage();

    const { setTitle } = useHead();

    useEffect(() => {
        if (app?.appName) {
            setTitle(app.appName);
        }
    }, [app?.appName, setTitle]);

    const appUrl = useMemo(() => {
        if (datasourceChangeInProgress) {
            return '';
        }

        const url = new URL(app.appUrl);

        if (app.appUrl.includes('/braincube-web/') && app.appUrl.includes('.jsp')) {
            if (url.searchParams.has('wid')) {
                url.searchParams.delete('wid');
                url.searchParams.append('wid', wid);
            } else {
                url.searchParams.append('wid', wid);
            }
        }

        const marioQueryParams = new URLSearchParams(location.search.slice(1));

        marioQueryParams.forEach((value, key) => url.searchParams.append(key, value));

        return url.toString();
    }, [datasourceChangeInProgress, app.appUrl, location.search, wid]);

    useEffect(() => {
        function doError() {
            setIsBusy(false);
            setError(true);
        }

        setIsBusy(true);

        if (appUrl.includes('/braincube-web/') && appUrl.includes('.jsp') && !siteChangeInProgress) {
            healthCheck(braincubeProduct.name)
                .then((response) => {
                    if (response.ok) {
                        setError(false);
                    } else if (response.status === 403) {
                        redirectToLogin();
                    } else {
                        doError();
                    }
                })
                .catch(doError);
        }
    }, [appUrl, setIsBusy, braincubeProduct.name, siteChangeInProgress]);

    const iframeKey = useMemo(() => {
        if (selectedElement) {
            return `${braincubeProduct.productId}-${selectedElement.uuid}-${app.pkgName}`;
        }

        return `${braincubeProduct.productId}-${app.pkgName}`;
    }, [braincubeProduct, selectedElement, app]);

    useEffect(() => {
        if (closeWorkspace) {
            setCloseWorkspace(false);

            if (!isJspPage) {
                history.goBack();
            }
        }
    }, [closeWorkspace, setCloseWorkspace, appUrl, history, isJspPage]);

    const onLoad = useCallback(() => {
        setIsBusy(false);

        if (ref.current.src.indexOf('cdn') === -1 && ref.current.contentDocument) {
            if (
                ref.current.contentDocument.body.childNodes.length === 0 ||
                ref.current.contentDocument.body.innerHTML === '<pre></pre>'
            ) {
                setIsBusy(true);
                ref.current.contentDocument.location.reload();
            }
        }
    }, [ref, setIsBusy]);

    if (siteChangeInProgress || !appUrl) {
        return null;
    }

    return error ? (
        <ErrorPage title={i18n.tc('error.sorry')} subtitle={i18n.tc('error.why', { applicationName: app.appName })}>
            <Stack direction="column">
                <SupportContact />

                <Stack direction="row" justifyContent="center" mt={1}>
                    <Button variant="contained" color="primary" onClick={reload}>
                        {i18n.tc('error.tryAgain')}
                    </Button>
                </Stack>
            </Stack>
        </ErrorPage>
    ) : (
        <StyledIframe
            key={iframeKey}
            ref={ref}
            onLoad={onLoad}
            title={app.appName}
            src={appUrl}
            allowFullScreen
            allow="clipboard-read; clipboard-write"
        />
    );
}

Iframe.propTypes = {
    app: PropTypes.shape({
        appName: PropTypes.string.isRequired,
        appUrl: PropTypes.string.isRequired,
        pkgName: PropTypes.string.isRequired,
    }).isRequired,
    wid: PropTypes.string.isRequired,
};

export default Iframe;
