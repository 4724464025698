import { fetchWithSiteBearer } from '@braincube/jwt-browser';

function getIsOnboarded(siteUuid) {
    return fetchWithSiteBearer(`https://${window.BC_API_ENDPOINTS_CONF.preference}/sites/${siteUuid}/isOnboarded`, {
        method: 'GET',
        headers: new Headers({ 'x-app-key': btoa(process.env.REACT_APP_PACKAGE_NAME) }),
    }).then((response) => {
        if (response.ok) {
            if (response.status === 204) {
                return Promise.reject(new Error('not boarded'));
            }

            if (response.status === 200) {
                return response.json().then((json) => {
                    if (json?.settings?.isOnboarded === 'false' || json?.settings?.isOnboarded === false) {
                        return Promise.reject(new Error('not boarded'));
                    }

                    return Promise.resolve(true);
                });
            }

            return Promise.resolve(true);
        }

        return Promise.resolve(true);
    });
}

export default getIsOnboarded;
