function redirectToLogin() {
    Object.keys(localStorage)
        .filter((key) => key.startsWith('braincube-jwt'))
        .forEach((key) => localStorage.removeItem(key));

    window.location.assign(`https://${window.BC_API_ENDPOINTS_CONF.sso}/?redirect=${window.location.href}`);
}

function logout() {
    return fetch(`https://${window.BC_API_ENDPOINTS_CONF.sso}/sso-server/ws/session/logout`, {
        credentials: 'include',
        method: 'GET',
    })
        .then(redirectToLogin)
        .catch(redirectToLogin);
}

export { redirectToLogin, logout };
