import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { CircularProgress, Toolbar, styled } from '@mui/material';

import { useSites } from '@braincube/header';

import { MarioContext } from 'App/contexts';
import MarioHeader from 'ContextualizedApp/components/Header';
import Routes from 'ContextualizedApp/components/Routes';
import { useApps } from 'ContextualizedApp/contexts/apps';
import Menu from 'Menu';

const StyledRootWrapper = styled('div')({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
});

const StyledMain = styled('main')({
    flex: 1,
});

const StyledCircularProgressContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

function ContextualizedApp() {
    const { isBusy } = useContext(MarioContext);
    const { braincubeProduct } = useSites();
    const { currentApp, tabApps } = useApps();

    return (
        <StyledRootWrapper>
            <Helmet>
                <title>{`Braincube${currentApp ? ` - ${currentApp.appName}` : ''}`}</title>
            </Helmet>

            <MarioHeader />
            <StyledMain>
                <Toolbar />
                {braincubeProduct && braincubeProduct.name && tabApps.length !== 0 && <Routes />}
                <Menu />
                {isBusy && (
                    <StyledCircularProgressContainer>
                        <CircularProgress color="secondary" />
                    </StyledCircularProgressContainer>
                )}
            </StyledMain>
        </StyledRootWrapper>
    );
}

export default ContextualizedApp;
