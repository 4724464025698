import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

function ResponsiveDisplay({ horizontal, vertical }) {
    const isVertical = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return isVertical ? vertical : horizontal;
}

ResponsiveDisplay.propTypes = {
    horizontal: PropTypes.node.isRequired,
    vertical: PropTypes.node.isRequired,
};

export default ResponsiveDisplay;
