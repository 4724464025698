import React, { createContext, useContext, useMemo, useState } from 'react';

const MenuContext = createContext({
    isOpen: false,
});

export function MenuContextProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);

    const value = useMemo(() => ({ isOpen, setIsOpen }), [isOpen]);

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}

export const useMenuContext = () => useContext(MenuContext);
