import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';

const IframeContext = createContext({
    ref: null,
    postMessage: () => {},
});

function IframeContextProvider({ children }) {
    const ref = useRef(null);
    const [closeWorkspace, setCloseWorkspace] = useState(false);
    const [datasourceChangeInProgress, setDatasourceChangeInProgress] = useState(false);

    useEffect(() => {
        function onMessage(event) {
            // eslint-disable-next-line
            if (event.data === 'needsRefresh') {
                const url = new URL(window.location.href);
                url.searchParams.delete('isQSPopup');
                window.location.replace(url.toString());
            }
            if (
                // eslint-disable-next-line no-prototype-builtins
                event.data.hasOwnProperty('event') &&
                // eslint-disable-next-line no-prototype-builtins
                event.data.hasOwnProperty('datasourceUuid') &&
                event.data.event === 'needsRefresh'
            ) {
                const url = new URL(window.location.href);
                url.searchParams.set('dataSourceUuid', event.data.datasourceUuid);
                url.searchParams.delete('isQSPopup');
                window.location.replace(url.toString());
            }

            if (event.data === 'closeWorkspace') {
                setCloseWorkspace(true);
            }
        }

        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    const postMessage = (message) => {
        if (ref.current) {
            ref.current.contentWindow.postMessage(message, '*');
        }
    };

    const value = useMemo(
        () => ({
            ref,
            postMessage,
            closeWorkspace,
            setCloseWorkspace,
            datasourceChangeInProgress,
            setDatasourceChangeInProgress,
        }),
        [closeWorkspace, datasourceChangeInProgress]
    );

    return <IframeContext.Provider value={value}>{children}</IframeContext.Provider>;
}

export { IframeContext, IframeContextProvider };
