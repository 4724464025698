import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v1 } from 'uuid';

import { useSites } from '@braincube/header';

import { useApps } from 'ContextualizedApp/contexts/apps';
import Iframe from 'Iframe';

function Routes() {
    const { selectedElement } = useSites();
    const { tabApps, apps } = useApps();
    // eslint-disable-next-line
    const [wid] = useMemo(() => v1().split('-'), [selectedElement?.uuid]);

    const appsRoutes = useMemo(
        () =>
            apps.map((tab) => (
                <Route key={tab.appUuid} exact path={`/${tab.pkgName}`}>
                    <Iframe app={tab} wid={wid} />
                </Route>
            )),
        [apps, wid]
    );

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={tabApps[0].pkgName} />
            </Route>

            {appsRoutes}
            <Redirect to="/" />
        </Switch>
    );
}

export default Routes;
