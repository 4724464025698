import { styled, CircularProgress } from '@mui/material';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { getLocaleKey, I18nProvider } from '@braincube/i18n';
import { headerLabels, useIsReady, useMe, useSites } from '@braincube/header';
import Tracker from '@braincube/tracker';

import { AppsContextProvider } from 'ContextualizedApp/contexts/apps';
import { IframeContextProvider } from 'Iframe/contexts/iframeContext';
import { MenuContextProvider } from 'Menu/contexts';
import { PresentationModeContextProvider } from 'Menu/components/PresentationMode/contexts';
import { MarioContext, MarioContextProvider } from 'App/contexts';
import { getIsOnboarded } from 'App/services/api';

import ContextualizedApp from '../ContextualizedApp';
import localesMap from '../services/lang';

const StyledLoaderContainer = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

const basename = window.location.hostname.indexOf('cdn') !== -1 ? process.env.PUBLIC_URL : '/';

function AppReady() {
    const { locale } = useContext(MarioContext);
    const localeKey = useMemo(() => getLocaleKey(locale), [locale]);
    const [isOnboarded, setIsOnboarded] = useState(false);
    const { siteAccessList, user } = useMe();
    const { selectedSite, braincubeProduct } = useSites();

    const labels = useMemo(
        () => ({
            ...headerLabels.getLabels(localeKey),
            ...localesMap.getLabels(localeKey),
        }),
        [localeKey]
    );

    useEffect(() => {
        if (selectedSite) {
            const foundAccess = siteAccessList.find((access) => access.site.siteUuid === selectedSite.siteUuid);

            if (foundAccess && foundAccess.accessRight === 'ADMIN' && user.ssoBranch !== 'CORPORATE') {
                getIsOnboarded(selectedSite.siteUuid)
                    .then(() => setIsOnboarded(true))
                    .catch((e) => {
                        if (e?.message === 'not boarded') {
                            window.location.replace(
                                `https://${window.BC_API_ENDPOINTS_CONF.cdn}/administration?siteUuid=${selectedSite.siteUuid}`
                            );
                        }
                    });
            } else {
                setIsOnboarded(true);
            }
        } else {
            setIsOnboarded(true);
        }
    }, [selectedSite, siteAccessList, user]);

    useEffect(() => {
        if (braincubeProduct?.name) {
            Tracker.configure({
                appName: 'mario',
                appVersion: process.env.REACT_APP_VERSION,
                apiName: braincubeProduct.name,
            });
        }
    }, [braincubeProduct]);

    return isOnboarded ? (
        <I18nProvider labels={labels} locale={localeKey}>
            <Router basename={basename}>
                <PresentationModeContextProvider>
                    <IframeContextProvider>
                        <MenuContextProvider>
                            <AppsContextProvider>
                                <ContextualizedApp />
                            </AppsContextProvider>
                        </MenuContextProvider>
                    </IframeContextProvider>
                </PresentationModeContextProvider>
            </Router>
        </I18nProvider>
    ) : null;
}

function App() {
    const isReady = useIsReady();

    if (!isReady) {
        return (
            <StyledLoaderContainer>
                <CircularProgress color="secondary" />
            </StyledLoaderContainer>
        );
    }

    return (
        <MarioContextProvider>
            <AppReady />
        </MarioContextProvider>
    );
}

export default App;
