import { fetchWithBearer } from '@braincube/jwt-browser';

import { fetchWithAppKey } from 'services/api';

function getBcVersion(apiName) {
    return fetchWithAppKey(`https://${window.BC_API_ENDPOINTS_CONF.api}/braincube/${apiName}/sso/version`).then((response) => {
        if (response.ok) {
            return response.text();
        }

        return Promise.reject();
    });
}

function postDatasource(bcId, apiName) {
    return fetchWithBearer(`https://${apiName}.${window.BC_API_ENDPOINTS_CONF.base}/braincube-web/rest/session/mb`, {
        method: 'POST',
        body: JSON.stringify({ id: bcId }),
    });
}

export { getBcVersion, postDatasource };
