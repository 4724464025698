import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Hidden, ListItem, ListItemText, ListItemIcon, Tooltip, styled } from '@mui/material';

import ResponsiveDisplay from './ResponsiveDisplay';

const StyledHorizontalTab = styled('div', { shouldForwardProp: (prop) => prop !== 'active' })(({ theme, active }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    borderRight: `1px solid ${theme.palette.common.white}`,
    color: active ? theme.palette.primary.main : theme.palette.common.white,
    cursor: active ? 'default' : 'pointer',
    position: 'relative',
    minWidth: theme.header.height,
}));

const StyledBorder = styled('div')(({ theme }) => ({
    position: 'absolute',
    background: theme.palette.primary.main,
    height: 3,
    width: '100%',
    bottom: 0,
}));

const tabTooltipOnly = ['sm', 'lg', 'xl'];

const horizontalTabLabelStyle = { marginLeft: 1 };

function HorizontalTab({ icon: Icon, label, active, onClick }) {
    const tab = (
        <StyledHorizontalTab data-testid="tab" onClick={onClick} active={active}>
            <Icon />
            <Hidden only="md">
                <Typography variant="body2" sx={horizontalTabLabelStyle}>
                    {label}
                </Typography>
            </Hidden>
            {active && <StyledBorder data-testid="active-tab" />}
        </StyledHorizontalTab>
    );

    return (
        <>
            <Hidden only={tabTooltipOnly}>
                <Tooltip data-testid="tab-tooltip" title={label}>
                    {tab}
                </Tooltip>
            </Hidden>
            <Hidden only="md">{tab}</Hidden>
        </>
    );
}

HorizontalTab.propTypes = {
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
};

HorizontalTab.defaultProps = {
    active: false,
};

const listItemStyles = { backgroundColor: 'primary.main' };

const primaryTypographyProps = { noWrap: true, display: 'block' };

function VerticalTab({ icon: Icon, label, active, onClick }) {
    return (
        <ListItem sx={active ? listItemStyles : undefined} selected={active} button onClick={onClick}>
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={primaryTypographyProps}>{label}</ListItemText>
        </ListItem>
    );
}

VerticalTab.propTypes = HorizontalTab.propTypes;
VerticalTab.defaultProps = HorizontalTab.defaultProps;

function Tab(props) {
    const horizontalTab = useMemo(() => <HorizontalTab {...props} />, [props]);
    const verticalTab = useMemo(() => <VerticalTab {...props} />, [props]);
    return <ResponsiveDisplay horizontal={horizontalTab} vertical={verticalTab} />;
}

Tab.propTypes = {
    /**
     * The icon that describes the action, must be an [SvgIcon](https://material-ui.com/api/svg-icon/) component instance.
     */
    icon: PropTypes.elementType.isRequired,
    /**
     * The label of the Tab.
     */
    label: PropTypes.string.isRequired,
};

export default Tab;
