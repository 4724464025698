import React from 'react';
import PropTypes from 'prop-types';

import { useMe } from '@braincube/header';

function InitialAvatar({ avatar: Avatar }) {
    const {
        user: { firstName, lastName },
    } = useMe();

    const initial = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

    return <Avatar>{initial}</Avatar>;
}

InitialAvatar.propTypes = {
    avatar: PropTypes.element.isRequired,
};

export default InitialAvatar;
