function needsRefresh(url) {
    try {
        const urlObject = new URL(url);

        return (
            process.env.NODE_ENV === 'production' &&
            urlObject.hostname !== window.location.hostname &&
            !window.location.hostname.startsWith('cdn')
        );
    } catch (e) {
        return false;
    }
}

export { needsRefresh };
export { getBcVersion, postDatasource } from 'ContextualizedApp/services/api/braincube';
