import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ListItemButton, ListItemText } from '@mui/material';
import { Label } from '@mui/icons-material';

import { useI18n } from '@braincube/i18n';
import { useSites } from '@braincube/header';

import { useMenuContext } from 'Menu/contexts';
import Toggler from 'Menu/components/Toggler';
import { getDisplayType, postDisplayType } from 'Menu/components/DisplayType/services';

const DISPLAY_TYPES = {
    local: 'Local',
    tag: 'Tag',
    standard: 'Standard',
};

const TogglerIcon = <Label />;

function DisplayTypeListItem({ displayTypeKey, reloadIframe, displayType, setDisplayType }) {
    const i18n = useI18n();
    const { setIsOpen } = useMenuContext();
    const { braincubeProduct } = useSites();

    const handleClick = useCallback(() => {
        setIsOpen(false);
        setDisplayType(DISPLAY_TYPES[displayTypeKey]);
        postDisplayType(DISPLAY_TYPES[displayTypeKey], braincubeProduct.name);
        reloadIframe();
    }, [braincubeProduct.name, displayTypeKey, reloadIframe, setDisplayType, setIsOpen]);

    return (
        <ListItemButton onClick={handleClick} selected={displayType === DISPLAY_TYPES[displayTypeKey]}>
            <ListItemText primary={i18n.tc(`menu.displayTypes.${displayTypeKey}`)} />
        </ListItemButton>
    );
}

DisplayTypeListItem.propTypes = {
    reloadIframe: PropTypes.func.isRequired,
    displayTypeKey: PropTypes.string.isRequired,
    displayType: PropTypes.string.isRequired,
    setDisplayType: PropTypes.func.isRequired,
};

function DisplayType({ reloadIframe }) {
    const [displayType, setDisplayType] = useState(DISPLAY_TYPES.local);
    const { braincubeProduct, siteChangeInProgress } = useSites();
    const i18n = useI18n();

    useEffect(() => {
        if (!siteChangeInProgress) {
            getDisplayType(braincubeProduct.name)
                .then(({ displayTypes }) => {
                    setDisplayType(Object.keys(displayTypes).find((key) => displayTypes[key] === true));
                })
                .catch(() => {
                    setDisplayType(DISPLAY_TYPES.local);
                });
        }
    }, [braincubeProduct.name, siteChangeInProgress]);

    const displayTypesListItems = useMemo(
        () =>
            Object.keys(DISPLAY_TYPES).map((displayTypeKey) => (
                <DisplayTypeListItem
                    displayType={displayType}
                    setDisplayType={setDisplayType}
                    key={displayTypeKey}
                    displayTypeKey={displayTypeKey}
                    reloadIframe={reloadIframe}
                />
            )),
        [displayType, reloadIframe]
    );

    return (
        <Toggler icon={TogglerIcon} label={i18n.tc(`menu.displayTypes.${displayType.toLowerCase()}`)}>
            {displayTypesListItems}
        </Toggler>
    );
}

DisplayType.propTypes = {
    reloadIframe: PropTypes.func.isRequired,
};

export default DisplayType;
