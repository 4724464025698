import { fetchWithBearer } from '@braincube/jwt-browser';

function fetchWithAppKey(url, options) {
    return fetchWithBearer(url, {
        headers: new Headers({ 'x-app-key': btoa(process.env.REACT_APP_PACKAGE_NAME) }),
        method: 'GET',
        ...options,
    });
}

export default fetchWithAppKey;
