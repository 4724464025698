import React from 'react';
import { Avatar, colors, styled } from '@mui/material';

import * as SvgIcons from '@braincube/svg';

import { usePresentationModeContext } from 'Menu/components/PresentationMode/contexts';
import InitialAvatar from 'InitialAvatar';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    marginRight: theme.spacing(1),
    backgroundColor: colors.grey[700],
    width: 36,
    height: 36,
    fontSize: theme.typography.body1.fontSize,
}));

const StyledPresentation = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    width: 36,
    height: 36,
}));

function MyAvatar() {
    const { presentationMode } = usePresentationModeContext();

    return presentationMode ? (
        <StyledPresentation>
            <SvgIcons.Presentation />
        </StyledPresentation>
    ) : (
        <InitialAvatar avatar={StyledAvatar} />
    );
}

export default MyAvatar;
