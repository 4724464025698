import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const StyledList = styled(List)(({ theme }) => ({
    '&> div': {
        paddingLeft: theme.spacing(4),
    },
}));

function Toggler({ icon, label, children }) {
    const [open, setOpen] = useState(false);

    const toggle = useCallback(() => {
        setOpen(!open);
    }, [open]);

    return (
        <>
            <ListItem button onClick={toggle}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} unmountOnExit>
                <StyledList component="div" disablePadding>
                    {children}
                </StyledList>
            </Collapse>
        </>
    );
}

Toggler.propTypes = {
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
};

export default Toggler;
