import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { usePreferences, useApps as useHeaderApps } from '@braincube/header';

import { usePresentationModeContext } from 'Menu/components/PresentationMode/contexts';

const AppsContext = createContext();

export function AppsContextProvider({ children }) {
    const { global } = usePreferences();
    const { presentationMode } = usePresentationModeContext();
    const history = useHistory();
    const apps = useHeaderApps();

    const reducer = useCallback(
        (prev, current) => {
            const found = apps.find((app) => app.pkgName === current);

            if (found) {
                prev.push(found);
            }

            return prev;
        },
        [apps]
    );

    const tabApps = useMemo(() => {
        const filteredTabApps = presentationMode ? ['braincube-dashboard', 'braincube-report'] : global.header.tabApps;

        return filteredTabApps.reduce(reducer, []).map((app, index) => ({
            ...app,
            tabId: index,
        }));
    }, [global.header.tabApps, presentationMode, reducer]);

    const widgetApps = useMemo(() => global.header.widgetApps.reduce(reducer, []), [global.header.widgetApps, reducer]);

    const profileApps = useMemo(() => global.header.profileApps.reduce(reducer, []), [global.header.profileApps, reducer]);

    const currentApp = useMemo(
        () => tabApps.find((tab) => tab.pkgName === history.location.pathname.replace('/', '')),
        [tabApps, history.location.pathname]
    );

    const value = useMemo(
        () => ({ tabApps, widgetApps, profileApps, currentApp, apps }),
        [profileApps, tabApps, widgetApps, currentApp, apps]
    );

    return <AppsContext.Provider value={value}>{children}</AppsContext.Provider>;
}

export const useApps = () => useContext(AppsContext);
