import { fetchWithBearer } from '@braincube/jwt-browser';

function postLang(lang, apiName) {
    return fetchWithBearer(`https://${apiName}.${window.BC_API_ENDPOINTS_CONF.base}/braincube-web/rest/session/locale`, {
        method: 'POST',
        body: JSON.stringify({ id: lang }),
    });
}

export default postLang;
